import React, { useState } from "react"
import styled from "styled-components"
import ImageZoom from "react-medium-image-zoom"
import { Composition } from "atomic-layout"
import Container from "./container"
import Link from "./link"

import zoom from "../images/zoom.svg"
import drawing from "../images/big-drawing.png"

const Wrapper = styled.div`
  background: #efefef;
`

const Title = styled.h4`
  color: #74c6d6;
  font-size: 1.3em;
  text-transform: uppercase;
  margin-bottom: 0.25em;
  @media (min-width: 48rem) {
    font-size: 1.5em;
  }
`

const Intro = styled.p`
  line-height: 1.2;
  font-size: 1.25em;
  @media (min-width: 48rem) {
    font-size: 1.3em;
  }
`

const Channel = ({ title, desc, link }) => (
  <div>
    <Title>{title}</Title>
    <p>
      <Link href={link}>{desc}</Link>
    </p>
  </div>
)

const areasMobile = `
  image
  content
`
const areasTablet = `
  image   content
`
const Info = () => {
  const [isZoomed, setIsZoomed] = useState(false)

  return (
    <Wrapper>
      <Composition
        as={Container}
        gutter={20}
        areas={areasMobile}
        areasLg={areasTablet}
      >
        {({ Image, Content }) => (
          <>
            <Image>
              <ImageZoom
                isZoomed={isZoomed}
                onZoom={() => setIsZoomed(true)}
                onUnzoom={() => setIsZoomed(false)}
                image={{
                  src: drawing,
                  alt: "GDJ Upstairs",
                  style: { maxWidth: 668, width: "100%", margin: 0 },
                }}
                zoomImage={{
                  src: drawing,
                  alt: "GDJ Upstairs",
                }}
              />
              <small
                style={{
                  marginLeft: "1em",
                  color: "#7C7C7C",
                  cursor: "pointer",
                }}
                onClick={() => setIsZoomed(true)}
              >
                <img
                  src={zoom}
                  alt="zoom"
                  style={{
                    margin: "0 1em",
                  }}
                />
                Enlarge Floorplan
              </small>
            </Image>

            <Content>
              <Intro>
                This modern, newly renovated North Melbourne co-working space is
                available from 01 July 2019. It’s a large light filled first
                floor space that comes with a kitchen area and installed desks.
              </Intro>
              <Intro>Enquire now for availability.</Intro>

              <Channel
                title="Call us"
                desc="Contact Cathy Grant on 0412 434 781"
                link="tel:0412434781"
              />
              <Channel
                title="Email us"
                desc="info@gdj.com.au"
                link="mailto:info@gdj.com.au"
              />
              <Channel
                title="Visit us"
                desc="15-19 Gracie St North Melbourne, VIC 3051"
                link="#map"
              />
            </Content>
          </>
        )}
      </Composition>
    </Wrapper>
  )
}

export default Info
