import React from "react"
import styled from "styled-components"
import Link from './link'

const Container = styled.footer`
  display: flex;
  font-size: 0.75em;
  padding: 1em 1.25em;
  color: #000;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    .spacer {
      display: none;
    }
  }
`

const Spacer = () => <div className="spacer" style={{ margin: "0 .75em" }}>•</div>

const Footer = () => (
  <Container>
    <div>
      © {new Date().getFullYear()}{" "}
      <Link href="http://gdj.com.au">GDJ Advertising</Link>
    </div>
    <Spacer />
    <div>
      <Link href="tel:0392749700">(03) 9274 9700</Link>
    </div>
    <Spacer />
    <div>
      <Link href="mailto:info@gdj.com.au">info@gdj.com.au</Link>
    </div>
    <Spacer />
    <div>15-19 Gracie St, North Melbourne, VIC 3051</div>
  </Container>
)

export default Footer
