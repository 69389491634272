import React from "react"
import Slider from "react-slick"
import styled from "styled-components"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import "slick-carousel/slick/slick.css"
import "./carousel.css"

const Caption = styled.div`
  padding: 1em;
  background: black;
  color: white;
  text-transform: uppercase;
  display: inline-block;
  left: 0;
  bottom: calc(2rem + 1vw);
  width: 100%;
  min-height: 78px;
  letter-spacing: 1px;
  line-height: 1.1;
  @media (min-width: 768px) {
    position: absolute;
    width: auto;
    min-height: auto;
  }
  @media (min-width: 1440px) {
    font-size: 1.75em;
    max-width: 520px;
  }
`

const Slide = ({ caption, ...props }) => (
  <>
    <Img {...props} style={{ margin: 0 }} />
    <Caption>{caption}</Caption>
  </>
)

const Carousel = () => {
  const settings = {
    dots: true,
    lazyLoad: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
  }
  return (
    <StaticQuery
      query={graphql`
        query {
          reception: file(relativePath: { eq: "0P7A9186@2x.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desks: file(relativePath: { eq: "ALC_0902.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          meetingRoom: file(relativePath: { eq: "0P7A9182@2x.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          kitchen: file(relativePath: { eq: "ALC_0918.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desk: file(relativePath: { eq: "ALC_0989.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Slider {...settings}>
          <Slide
            fluid={data.reception.childImageSharp.fluid}
            caption="Located in a modern, North Melbourne Office"
          />
          <Slide
            fluid={data.desks.childImageSharp.fluid}
            caption="Custom desk spaces for individuals or businesses"
          />
          <Slide
            fluid={data.meetingRoom.childImageSharp.fluid}
            caption="Access to meeting rooms"
          />
          <Slide
            fluid={data.kitchen.childImageSharp.fluid}
            caption="Shared kitchen facilities"
          />
          <Slide
            fluid={data.desk.childImageSharp.fluid}
            caption="All desks have sit stand capabilities"
          />
        </Slider>
      )}
    />
  )
}

export default Carousel
