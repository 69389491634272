import React from "react"
import styled from "styled-components"
import { Composition } from "atomic-layout"
import Benefit from "./benefit"
import Container from "./container";

import proximity from "../images/proximity.svg"
import flexibility from "../images/flexibility.svg"
import access_to_gdj from "../images/access_to_gdj.svg"
import wifi from "../images/wifi.svg"
import meeting_room from "../images/meeting_room.svg"
import permanent_access from "../images/permanent_access.svg"

const Title = styled.h2`
  font-weight: bold;
  text-align: center;
  margin-bottom: 2.25em;
  font-size: 1.75em;
  @media (min-width: 48rem) {
    font-size: 2.25em;
  }
`

// Define layout areas as a string
const areasMobile = `
  proximity
  flexibility
  access
  wifi
  meeting
  permanent
`
const areasTablet = `
  proximity   flexibility
  wifi        access
  meeting     permanent
`
const areasDesktop = `
  proximity   flexibility   access
  wifi        meeting       permanent
`

const Benefits = () => (
  <Container>
    <Title>CO-WORKING SPACE FEATURES</Title>
    <Composition
      areas={areasMobile}
      areasMd={areasTablet}
      areasLg={areasDesktop}
      autoCols={'1fr'}
      gutter={20}
    >
      {({
        Proximity,
        Flexibility,
        Access,
        Wifi,
        Meeting,
        Permanent,
      }) => (
        <>
          <Proximity
            as={Benefit}
            icon={proximity}
            title="Proximity"
            desc="The space is ideally located close to Macaulay train station, Citylink, a supermarket, cafes and the North Melbourne Recreation Centre."
          />
          <Flexibility
            as={Benefit}
            icon={flexibility}
            title="Flexibility"
            desc="We can work with you to customise the space to suit your needs. Rent a single desk, multiple desks or entire sections of the co-working space."
          />
          <Access
            as={Benefit}
            icon={access_to_gdj}
            title="Shared Reception"
            desc="Enjoy the benefits of a shared reception area, including a couched waiting space, in the downstairs entrance of the building."
          />
          <Wifi
            as={Benefit}
            icon={wifi}
            title="WIFI"
            desc="The space is equipped with a superfast internet connection with a 100 Mbps upload and download speed."
          />
          <Meeting
            as={Benefit}
            icon={meeting_room}
            title="Meeting Room Access"
            desc="You’ll also have access to two meeting rooms with a capacity for 8 or 14 people along with break out zones."
          />
          <Permanent
            as={Benefit}
            icon={permanent_access}
            title="Price"
            desc="$190 per week per seat all inclusive."
          />
        </>
      )}
    </Composition>
  </Container>
)

export default Benefits
