import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled.div`
  display: grid;
  grid-gap: 1.75em;
  grid-template-columns: 62px auto;
`

const Title = styled.p`
  font-size: 1.25em;
  font-weight: bold;
`

const Benefit = ({ icon, title, desc }) => (
  <Container>
    <img src={icon} alt={title} />
    <div>
      <Title>{title}</Title>
      <p>{desc}</p>
    </div>
  </Container>
)

Benefit.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
}

export default Benefit
