import React from "react"
import styled from "styled-components"
import Map from "./map"

const Title = styled.h2`
  text-align: center;
  background: black;
  color: white;
  margin: 0;
  padding: 0.5em;
  font-size: 1em;
  @media (min-width: 48rem) {
    font-size: 1.4em;
  }
`

const Location = () => (
  <>
    <Title>Where we’re located.</Title>
    <div style={{ height: "40vh", position: "relative" }}>
      <Map />
    </div>
  </>
)

export default Location
