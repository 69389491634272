import React from "react"

import SEO from "../components/seo"
import Header from "../components/header"
import Carousel from "../components/carousel"
import Info from '../components/info'
import Footer from '../components/footer'
import Benefits from "../components/benefits"
import Location from "../components/location"
import "../components/layout.css"

const IndexPage = () => (
  <>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Header />
    <main>
      <Carousel />
      <Info />
      <Benefits />
      <Location />
    </main>
    <Footer />
  </>
)

export default IndexPage
