import React from "react"
import styled from "styled-components"
import logo from "../images/gdj-logo.svg"
import overlayImage from "../images/overlay.svg"

const Wrapper = styled.header`
  background: #74c6d6;
  position: relative;
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  display: grid;
  grid-auto-flow: column;
`

const Title = styled.h1`
  color: white;
  line-height: 1.5;
  max-width: 430px;
  margin: 1.45rem 1rem 0;

  > span {
    background-color: black;
    padding: 4px 16px;
  }

  @media (max-width: 700px) {
    font-size: 1.25rem;
    max-width: 250px;
  }
`

const Logo = styled.img`
  margin-right: 1rem;
  width: 20vw;
  max-width: 112px;
  @media (max-width: 374px) {
    margin-right: 0;
  }
`

const Overlay = styled.img`
  position: absolute;
  width: 100%;
  z-index: 5;
  min-width: 430px;
  /* pixel bug */
  transform: scale(1.01);
  margin-top: -1px;
`

const Header = () => (
  <Wrapper>
    <Container>
      <Title>
        The co-working space that <span>works with you.</span>
      </Title>

      <Logo src={logo} alt="GDJ" />
    </Container>
    <Overlay src={overlayImage} alt="" />
  </Wrapper>
)

export default Header
