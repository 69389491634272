import styled from "styled-components"

const Link = styled.a`
  color: #000;
  text-decoration: none;
  transition: color 0.1s ease-out;
  &:hover {
    color: #74c6d6;
  }
`

export default Link
