import React, { Component } from "react"
import { Map, Marker, GoogleApiWrapper } from "google-maps-react"

export class MapContainer extends Component {
  render() {
    return (
      <Map
        initialCenter={{
          lat: -37.7973259,
          lng: 144.9380444,
        }}
        mapTypeControl={false}
        google={this.props.google}
        zoom={14}
      >
        <Marker
          title={"GDJ Advertising"}
          name={"GDJ Advertising"}
          position={{ lat: -37.7973259, lng: 144.9380444 }}
        />
      </Map>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBI_-7i7kbivi3hvv8Gc9h7q2zHfHhTLwA",
})(MapContainer)
